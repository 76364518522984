/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/profile-ong',
      beforeEnter: authenticate,
      component: () => import('@/views/app/Index'),
      children: [
        {
          path: '/profile-ong',
          name: 'profile-ong.index',
          component: () => import('@/views/app/pages/profile-ong/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/admin-projet',
          name: 'admin-projet.index',
          component: () => import('@/views/app/pages/admin-projets/Index'),
          beforeEnter: authenticate,
        },
        {
            path: '/association-di/:project',
            name: 'association-di.index',
            component: () => import('@/views/app/pages/association-domaine/Index'),
            beforeEnter: authenticate,
        },
        {
            path: '/association-agr/:project',
            name: 'association-agr.index',
            component: () => import('@/views/app/pages/association-agr/Index'),
            beforeEnter: authenticate,
        },
        {
          path: '/finance-projet',
          name: 'finance-projet.index',
          component: () => import('@/views/app/pages/finance-projet/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/domaine-intervention',
          name: 'domaine-intervention.index',
          component: () => import('@/views/app/pages/domaine-intervention/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/domaine-intervention-view/:collect',
          name: 'domaine-intervention.view',
          component: () => import('@/views/app/pages/domaine-intervention/View'),
          beforeEnter: authenticate,
        },
        {
          path: '/agr',
          name: 'agr.index',
          component: () => import('@/views/app/pages/agr/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/agr-view/:collect',
          name: 'agr.view',
          component: () => import('@/views/app/pages/agr/View'),
          beforeEnter: authenticate,
        },
        {
          path: '/parametre-domaine-intervention',
          name: 'parametre-domaine-intervention.index',
          component: () => import('@/views/app/pages/parametre-domaine-intervention/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/parametre-domaine-intervention/:DI/ligne-domaine-intervention',
          name: 'ligne-domaine-intervention.index',
          component: () => import('@/views/app/pages/ligne-domaine-intervention/Index'),
          beforeEnter: authenticate,
        },
        {
            path: '/parametre-agr/:AGR/ligne-agr',
            name: 'ligne-agr.index',
            component: () => import('@/views/app/pages/ligne-agr/Index'),
            beforeEnter: authenticate,
          },
        {
          path: '/parametre-agr',
          name: 'parametre-agr.index',
          component: () => import('@/views/app/pages/parametre-agr/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/visualisation-graphe',
          name: 'visualisation-graphe.index',
          component: () => import('@/views/app/pages/tableaux-sytheses/Graph'),
          beforeEnter: authenticate,
        },
        {
          path: '/visualisation-tableau',
          name: 'visualisation-tableau.index',
          component: () => import('@/views/app/pages/tableaux-sytheses/Table-View'),
          beforeEnter: authenticate,
        },
        {
          path: '/compte-admins',
          name: 'compte-admins.index',
          component: () => import('@/views/app/pages/compte-admins/Index'),
          beforeEnter: authenticate,
        },
        {
          path: '/compte-partenaires',
          name: 'compte-partenaires.index',
          component: () => import('@/views/app/pages/compte-partenaires/Index'),
          beforeEnter: authenticate,
        },
      ],
    },
    {
      path: '/forgot',
      component: () => import('@/views/app/sessions/Forgot'),
    },

    {
      path: '/login',
      meta: {
        beforeResolve (routeTo, routeFrom, next) {
                 if (localStorage.getItem('token') != null) {
                  next({ name: 'index' })
                 } else {
                  next()
                 }
         },
      },
      name: 'login',
      component: () => import('@/views/app/sessions/SignInTwo'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  next()
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
})

export default router
